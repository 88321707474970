import { createApp } from "vue";
import App from "./App.vue";

import { SITE_TITLE } from "./consts.js";
import { executeOnPageLoad } from './fetchRequestLogic.js'; // 引入fetch请求逻

import VMdPreview from "@kangc/v-md-editor/lib/preview";
import "@kangc/v-md-editor/lib/style/preview.css";
import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
import "@kangc/v-md-editor/lib/theme/style/github.css";

import "./styles/overgithub.css";

import "./styles/theme.css";
import "./styles/iconfont.js";

// https://highlightjs.org/
import hljs from 'highlight.js';

VMdPreview.use(githubTheme, {
  Hljs: hljs
});

// 创建 Vue 应用实例
const app = createApp(App);

// 注册全局属性 $site
app.config.globalProperties.$site = {
  name: SITE_TITLE,
  note: {},
};

// 注册 VMdPreview 插件
app.use(VMdPreview);

// 挂载应用到 DOM 元素
app.mount('#app');

// 页面加载完成后执行请求逻辑
executeOnPageLoad();
 