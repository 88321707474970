<template>
  <main>
    <div class="site-home">
      <div class="site-box">
        <h1 class="site-title">
          <span>Hello, </span>
          <span> I'm {{ $site.name }}.</span>
        </h1>
        <div class="site-style">
          <h2 id="cursor-effect">&lt;Developer /&gt;</h2>
          <h2>&nbsp;,&nbsp;&nbsp;</h2>
          <div id="border-effect">
            <h2>Shutterbug</h2>
            <div class="border-effect-box border-effect-box1"></div>
            <div class="border-effect-box border-effect-box2"></div>
            <div class="border-effect-box border-effect-box3"></div>
            <div class="border-effect-box border-effect-box4"></div>
          </div>
        </div>

        <div class="site-label">
          <h2>#Traveler</h2>
          <h2>#Pisces</h2>
          <h2>
            <a
              href="https://www.16personalities.com/isfp-personality"
              target="_blank"
              >#ISFP</a
            >
          </h2>
        </div>

        <div class="site-about">
          <div>
            <span>A ordinary code developer and amateur shutterbug.</span>
          </div>
          <!-- Currently working at XX. -->
          <div><span>Is currently a freelance, Open to work.</span></div>
          <div><span>I believe it's never too late to change.</span></div>
        </div>
      </div>

      <!-- translate="no" 拒绝翻译软件进行翻译 -->
      <span style="display: none" translate="no"> 📫 wain@vastwain.com</span>

      <div class="site-link">
        <a href="/note-waterfall">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-baokanzazhi"></use>
          </svg>
          Blog
        </a>
        <a href="/cycling" target="_blank">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jiankangjianmei"></use>
          </svg>
          Cycling
        </a>
        <a href="https://nav.vastwain.xyz" target="_blank">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-daohang"></use>
          </svg>
          Navigation
        </a>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style>
.site-home {
  display: flex;
  justify-content: center;
  flex-direction: column;

  gap: 2rem;
  margin: 0 auto;
  max-width: 100ch;
  padding: 6rem 3.5rem 5rem;
}

.site-box {
  display: grid;
  gap: 1.5rem;
  align-items: center;
  justify-content: start;
}

.site-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 3em;
}

.site-style {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
}

.site-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
}

.site-label h2 {
  margin: 0 5px;
}

.site-label h2:hover {
  letter-spacing: 0.3px;
}

.site-about {
  gap: 0.5rem;
  display: grid;
  align-items: center;
  justify-content: start;
}

.site-link {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.site-link a {
  display: inline-flex;
  padding: 0.5rem;
  margin: 0.5rem 0.5rem 0 0;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  line-height: 1.5rem;
  font-size: 0.875em;

  border-radius: 0.375rem;
  background-color: rgba(249, 250, 251, 1);
}

.site-link svg {
  display: inline-block;
  margin-right: 0.5rem;
  line-height: 1.875rem;
  font-size: 0.875em;
}

.icon {
  width: 1.875rem;
  height: 1.875rem;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.site-projects {
  display: grid;
  gap: 2rem;
  max-width: 100ch;
  align-items: center;
  justify-items: start;
  align-content: center;
}

@media screen and (min-width: 640px) {
  .grid-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.peoject-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#cursor-effect {
  position: relative; /* 为伪元素定位做准备 */
  display: inline-block; /* 使伪元素相对于h1进行定位 */
}

#cursor-effect:hover::after {
  content: "|"; /* 光标符号 */
  animation: blink 1s infinite step-end; /* 应用闪烁动画 */
  position: absolute; /* 绝对定位，使其位于文本末尾 */
  right: -0.2em; /* 调整位置，与文本保持适当距离 */
  color: var(--blue); /* 设置光标颜色 */
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#border-effect {
  display: inline;
  position: relative;
  transition: 0.2s;
  border: 1px solid var(--blue);
  padding: 0 4px;
  background-color: hsla(0, 0%, 100%, 0.05);
}

#border-effect:hover {
  border: 0.5px dashed var(--blue);
  background-color: var(--blue);
  color: #ffffff;
  opacity: 0.8;
  letter-spacing: 0.2px;
}

.border-effect-box {
  width: 6px;
  height: 6px;
  position: absolute;
  border: 0.5px solid var(--blue);
  background-color: #d8e5ff;
}

.border-effect-box1 {
  top: -3px;
  left: -3px;
}

.border-effect-box2 {
  top: -3px;
  right: -3px;
}

.border-effect-box3 {
  bottom: -3px;
  left: -3px;
}

.border-effect-box4 {
  bottom: -3px;
  right: -3px;
}
</style>
