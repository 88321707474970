export const SITE_TITLE = `VastWain`;

export const CN_GEOJSON = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        adcode: 110000,
        name: "北京市",
        center: [116.405285, 39.904989],
        centroid: [116.41995, 40.18994],
        childrenNum: 16,
        level: "province",
        acroutes: [100000],
        parent: { adcode: 100000 },
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [117.223901, 40.375538],
              [117.226661, 40.378558],
              [117.229045, 40.386843],
              [117.235382, 40.389556],
              [117.23695, 40.394078],
              [117.240694, 40.394417],
              [117.240484, 40.39763],
              [117.236616, 40.400844],
              [117.237515, 40.407786],
              [117.234085, 40.417149],
              [117.243872, 40.422848],
              [117.246737, 40.426883],
              [117.257654, 40.435372],
              [117.263342, 40.442375],
              [117.252635, 40.446038],
              [117.252154, 40.450459],
              [117.243308, 40.455428],
              [117.236532, 40.456558],
              [117.233207, 40.463204],
              [117.237243, 40.468785],
              [117.230907, 40.470463],
              [117.225783, 40.47585],
              [117.22846, 40.481301],
              [117.21792, 40.494589],
              [117.212065, 40.494685],
              [117.208426, 40.498071],
              [117.208572, 40.501102],
              [117.21449, 40.50739],
              [117.212504, 40.507906],
              [117.215013, 40.513273],
              [117.219969, 40.514321],
              [117.230405, 40.511162],
              [117.239543, 40.516723],
              [117.246821, 40.511968],
              [117.255562, 40.514934],
              [117.263133, 40.513145],
              [117.264115, 40.517271],
              [117.26146, 40.51906],
              [117.255123, 40.527973],
              [117.252007, 40.53632],
              [117.247427, 40.540236],
              [117.250606, 40.542024],
              [117.24954, 40.548179],
              [117.25964, 40.552867],
              [117.268507, 40.559842],
              [117.273191, 40.561501],
              [117.279256, 40.560342],
              [117.285592, 40.565061],
              [117.299562, 40.566801],
              [117.311837, 40.578026],
              [117.325451, 40.578155],
              [117.328442, 40.575948],
              [117.334611, 40.576464],
              [117.342767, 40.581585],
              [117.350023, 40.582197],
              [117.353014, 40.578831],
              [117.365917, 40.575965],
              [117.369221, 40.57036],
              [117.375453, 40.567799],
              [117.378422, 40.56337],
              [117.389297, 40.561244],
              [117.39465, 40.567912],
              [117.400589, 40.569345],
              [117.40358, 40.574257],
              [117.413471, 40.569893],
              [117.42123, 40.569104],
              [117.429992, 40.576126],
              [117.429804, 40.579298],
              [117.423217, 40.58144],
              [117.420623, 40.590875],
              [117.421753, 40.593178],
              [117.414747, 40.600728],
              [117.412739, 40.605123],
              [117.419954, 40.617517],
              [117.430285, 40.626014],
              [117.438002, 40.625692],
              [117.442289, 40.627977],
              [117.448876, 40.62838],
              [117.45402, 40.642844],
              [117.451448, 40.646577],
              [117.456467, 40.649167],
              [117.449106, 40.651596],
              [117.462009, 40.653076],
              [117.464309, 40.648652],
              [117.467885, 40.649521],
              [117.473093, 40.644453],
              [117.475539, 40.644421],
              [117.477986, 40.635331],
              [117.486163, 40.633496],
              [117.489948, 40.636023],
              [117.500425, 40.6362],
              [117.501972, 40.644518],
              [117.505026, 40.646142],
              [117.50283, 40.653076],
              [117.513913, 40.656196],
              [117.514583, 40.660523],
              [117.502934, 40.669674],
              [117.493357, 40.67527],
              [117.484741, 40.677087],
              [117.482399, 40.679033],
              [117.471503, 40.674338],
              [117.465188, 40.673534],
              [117.453958, 40.677618],
              [117.442226, 40.676605],
              [117.437332, 40.683599],
              [117.432711, 40.681622],
              [117.426437, 40.685304],
              [117.419348, 40.68696],
              [117.414852, 40.685947],
              [117.409226, 40.687281],
              [117.397223, 40.683776],
              [117.386996, 40.684178],
              [117.378192, 40.678808],
              [117.37058, 40.679708],
              [117.359748, 40.673919],
              [117.342411, 40.673437],
              [117.336681, 40.666956],
              [117.337622, 40.664447],
              [117.331851, 40.661504],
              [117.321164, 40.658287],
              [117.290423, 40.660185],
              [117.278608, 40.664463],
              [117.278629, 40.667551],
              [117.273401, 40.670076],
              [117.267754, 40.676669],
              [117.261397, 40.681155],
              [117.256775, 40.679467],
              [117.241635, 40.676669],
              [117.234336, 40.680577],
              [117.233207, 40.683583],
              [117.218715, 40.689484],
              [117.217335, 40.69196],
              [117.210622, 40.691976],
              [117.208405, 40.694435],
              [117.202361, 40.695577],
              [117.197802, 40.694291],
              [117.193996, 40.696268],
              [117.182453, 40.697072],
              [117.180947, 40.694082],
              [117.176639, 40.693567],
              [117.169236, 40.699097],
              [117.16474, 40.699628],
              [117.159491, 40.696332],
              [117.147571, 40.698968],
              [117.142531, 40.6972],
              [117.132493, 40.698663],
              [117.128792, 40.700913],
              [117.117792, 40.700078],
              [117.110661, 40.708243],
              [117.095144, 40.705559],
              [117.086047, 40.702055],
              [117.081153, 40.702617],
              [117.076804, 40.700029],
              [117.058338, 40.70154],
              [117.054887, 40.699804],
              [117.044494, 40.700367],
              [117.036108, 40.697265],
              [117.035585, 40.694467],
              [117.031047, 40.692136],
              [117.027848, 40.694355],
              [117.018291, 40.696011],
              [117.013418, 40.694082],
              [117.005513, 40.694853],
              [117.002481, 40.697345],
              [116.990456, 40.701203],
              [116.988177, 40.703164],
              [116.979938, 40.702826],
              [116.977533, 40.705559],
              [116.969628, 40.706362],
              [116.965111, 40.709593],
              [116.96647, 40.71525],
              [116.960134, 40.721083],
              [116.946645, 40.726916],
              [116.942714, 40.729857],
              [116.940706, 40.739786],
              [116.926548, 40.744894],
              [116.923516, 40.750596],
              [116.927908, 40.757824],
              [116.923181, 40.766897],
              [116.923391, 40.773722],
              [116.904569, 40.777286],
              [116.898589, 40.77674],
              [116.895013, 40.781733],
              [116.89495, 40.790675],
              [116.896686, 40.796438],
              [116.889073, 40.798348],
              [116.886961, 40.801076],
              [116.880207, 40.804367],
              [116.882172, 40.814172],
              [116.876735, 40.818456],
              [116.876171, 40.8212],
              [116.870378, 40.821601],
              [116.861448, 40.825356],
              [116.860633, 40.830457],
              [116.855425, 40.835447],
              [116.848587, 40.837147],
              [116.84819, 40.839313],
              [116.839741, 40.839024],
              [116.837775, 40.841542],
              [116.831815, 40.842585],
              [116.828009, 40.841109],
              [116.823471, 40.842681],
              [116.820732, 40.848263],
              [116.813622, 40.848423],
              [116.805947, 40.840836],
              [116.802496, 40.842392],
              [116.802413, 40.851198],
              [116.796996, 40.854886],
              [116.797226, 40.860034],
              [116.776795, 40.878376],
              [116.772362, 40.87852],
              [116.769392, 40.882961],
              [116.762135, 40.880765],
              [116.758539, 40.881983],
              [116.759501, 40.889854],
              [116.750257, 40.891665],
              [116.739759, 40.896665],
              [116.730432, 40.897771],
              [116.726145, 40.901185],
              [116.723678, 40.906313],
              [116.716881, 40.910175],
              [116.713347, 40.910431],
              [116.717111, 40.921695],
              [116.722318, 40.92743],
              [116.713891, 40.929416],
              [116.712197, 40.934846],
              [116.70722, 40.934029],
              [116.702786, 40.936512],
              [116.702368, 40.940628],
              [116.696178, 40.94452],
              [116.689298, 40.951118],
              [116.687248, 40.962551],
              [116.677921, 40.970972],
              [116.677921, 40.975983],
              [116.681267, 40.980737],
              [116.685304, 40.982641],
              [116.682543, 40.986259],
              [116.683066, 41.000486],
              [116.69095, 41.007254],
              [116.690866, 41.012982],
              [116.693836, 41.013686],
              [116.698855, 41.021253],
              [116.695739, 41.025396],
              [116.69509, 41.033265],
              [116.691347, 41.037503],
              [116.692246, 41.040813],
              [116.688629, 41.044651],
              [116.682878, 41.041789],
              [116.67698, 41.042732],
              [116.673279, 41.046378],
              [116.665207, 41.046682],
              [116.657009, 41.051303],
              [116.653914, 41.05626],
              [116.647431, 41.059393],
              [116.641158, 41.058322],
              [116.637937, 41.060497],
              [116.630848, 41.0608],
              [116.624093, 41.054437],
              [116.61646, 41.053382],
              [116.617736, 41.048649],
              [116.613491, 41.040782],
              [116.614118, 41.036096],
              [116.617589, 41.034704],
              [116.621228, 41.028978],
              [116.62288, 41.020693],
              [116.621897, 41.015749],
              [116.619179, 41.01423],
              [116.614892, 41.003574],
              [116.617067, 40.998725],
              [116.614515, 40.983314],
              [116.597764, 40.97475],
              [116.589692, 40.976703],
              [116.574928, 40.986307],
              [116.569177, 40.991636],
              [116.561231, 40.993461],
              [116.558617, 40.988627],
              [116.547826, 40.988003],
              [116.541991, 40.99026],
              [116.535634, 40.988675],
              [116.533417, 40.985698],
              [116.524718, 40.981073],
              [116.519573, 40.981569],
              [116.51629, 40.975198],
              [116.504516, 40.975919],
              [116.496737, 40.978432],
              [116.493726, 40.977919],
              [116.485612, 40.982465],
              [116.47894, 40.979104],
              [116.474193, 40.978608],
              [116.464135, 40.984498],
              [116.455519, 40.980481],
              [116.451713, 40.968667],
              [116.453302, 40.964584],
              [116.447446, 40.95384],
              [116.454829, 40.949533],
              [116.455372, 40.945433],
              [116.462315, 40.935231],
              [116.461541, 40.932684],
              [116.467209, 40.931322],
              [116.468422, 40.925091],
              [116.473608, 40.91974],
              [116.477581, 40.901746],
              [116.474047, 40.896008],
              [116.464344, 40.896329],
              [116.458676, 40.900592],
              [116.45073, 40.901345],
              [116.448492, 40.899919],
              [116.436614, 40.89939],
              [116.430905, 40.903364],
              [116.418922, 40.902339],
              [116.413715, 40.899758],
              [116.404764, 40.905736],
              [116.398533, 40.906024],
              [116.396316, 40.911264],
              [116.39274, 40.913123],
              [116.384877, 40.922848],
              [116.384019, 40.928535],
              [116.379732, 40.933228],
              [116.379941, 40.935775],
              [116.370343, 40.943655],
              [116.364801, 40.942999],
              [116.358925, 40.93608],
              [116.35012, 40.936048],
              [116.339894, 40.929416],
              [116.338828, 40.925732],
              [116.334122, 40.920829],
              [116.33544, 40.910495],
              [116.334436, 40.90463],
              [116.342236, 40.899887],
              [116.344683, 40.894694],
              [116.353864, 40.887786],
              [116.360514, 40.884885],
              [116.365972, 40.880188],
              [116.366599, 40.876645],
              [116.374881, 40.871531],
              [116.38174, 40.863465],
              [116.389707, 40.861814],
              [116.391778, 40.854838],
              [116.399306, 40.850492],
              [116.406145, 40.837933],
              [116.406458, 40.833361],
              [116.414991, 40.829318],
              [116.422477, 40.822772],
              [116.436823, 40.820735],
              [116.439897, 40.815038],
              [116.440002, 40.809133],
              [116.450981, 40.801927],
              [116.452152, 40.798059],
              [116.457233, 40.7983],
              [116.461416, 40.78854],
              [116.460663, 40.78244],
              [116.465849, 40.774525],
              [116.465452, 40.772742],
              [116.45395, 40.76587],
              [116.444414, 40.76921],
              [116.437722, 40.766865],
              [116.431846, 40.768246],
              [116.424861, 40.767443],
              [116.416496, 40.76937],
              [116.4143, 40.777912],
              [116.407838, 40.780417],
              [116.403217, 40.778635],
              [116.392635, 40.778394],
              [116.379837, 40.772325],
              [116.37097, 40.772453],
              [116.367687, 40.77088],
              [116.361204, 40.772646],
              [116.353111, 40.770221],
              [116.342947, 40.773096],
              [116.33314, 40.772694],
              [116.330149, 40.77377],
              [116.31756, 40.77218],
              [116.313168, 40.770205],
              [116.30794, 40.763734],
              [116.311119, 40.75511],
              [116.30794, 40.752122],
              [116.304762, 40.755656],
              [116.29786, 40.756812],
              [116.290918, 40.763814],
              [116.281402, 40.763926],
              [116.277366, 40.76163],
              [116.273456, 40.762883],
              [116.274167, 40.766335],
              [116.26988, 40.770703],
              [116.269587, 40.777158],
              [116.261013, 40.782938],
              [116.257834, 40.787898],
              [116.247943, 40.791831],
              [116.245224, 40.78838],
              [116.235019, 40.78313],
              [116.235625, 40.775135],
              [116.231757, 40.77149],
              [116.229979, 40.762417],
              [116.23366, 40.759896],
              [116.223308, 40.753793],
              [116.220485, 40.749183],
              [116.220359, 40.744669],
              [116.213249, 40.740139],
              [116.210551, 40.741713],
              [116.204717, 40.739946],
              [116.2057, 40.733038],
              [116.197753, 40.7269],
              [116.192274, 40.724779],
              [116.184913, 40.713675],
              [116.181128, 40.712438],
              [116.177907, 40.707889],
              [116.176213, 40.700544],
              [116.171341, 40.695979],
              [116.173683, 40.689034],
              [116.171487, 40.68167],
              [116.168413, 40.67892],
              [116.167597, 40.672633],
              [116.162683, 40.662437],
              [116.151432, 40.663338],
              [116.142858, 40.666972],
              [116.13694, 40.667648],
              [116.125229, 40.654089],
              [116.113225, 40.648845],
              [116.111531, 40.646287],
              [116.112744, 40.640946],
              [116.120419, 40.633287],
              [116.122071, 40.629989],
              [116.118349, 40.627961],
              [116.1044, 40.626996],
              [116.098879, 40.630584],
              [116.088507, 40.626626],
              [116.08583, 40.623825],
              [116.076691, 40.619883],
              [116.073492, 40.612125],
              [116.069811, 40.610258],
              [116.062722, 40.610322],
              [116.058289, 40.607006],
              [116.050907, 40.606121],
              [116.04457, 40.602032],
              [116.032357, 40.599875],
              [116.030036, 40.597364],
              [116.028509, 40.607328],
              [116.025268, 40.60654],
              [116.005109, 40.584097],
              [115.996116, 40.58392],
              [115.995238, 40.579862],
              [115.982147, 40.579008],
              [115.97512, 40.590779],
              [115.971983, 40.60237],
              [115.965877, 40.601002],
              [115.967404, 40.605896],
              [115.955107, 40.609534],
              [115.948331, 40.608809],
              [115.944672, 40.611095],
              [115.935031, 40.613316],
              [115.928297, 40.612753],
              [115.920372, 40.616632],
              [115.907803, 40.617291],
              [115.897849, 40.608101],
              [115.894733, 40.606878],
              [115.888146, 40.597026],
              [115.885406, 40.595223],
              [115.867777, 40.595786],
              [115.86623, 40.593371],
              [115.854895, 40.590151],
              [115.846175, 40.593049],
              [115.827374, 40.587027],
              [115.820662, 40.568234],
              [115.82154, 40.563305],
              [115.819804, 40.559343],
              [115.815036, 40.55741],
              [115.804915, 40.55865],
              [115.79908, 40.5577],
              [115.7922, 40.561292],
              [115.784713, 40.558376],
              [115.776327, 40.552158],
              [115.773902, 40.548582],
              [115.770179, 40.548002],
              [115.763885, 40.540574],
              [115.759577, 40.538914],
              [115.75506, 40.540042],
              [115.752968, 40.536288],
              [115.755624, 40.531019],
              [115.748702, 40.526087],
              [115.743788, 40.518464],
              [115.743098, 40.513854],
              [115.73605, 40.503988],
              [115.743077, 40.494959],
              [115.744039, 40.498249],
              [115.768088, 40.498345],
              [115.774529, 40.493911],
              [115.782204, 40.492073],
              [115.776202, 40.482704],
              [115.776683, 40.477511],
              [115.771559, 40.47285],
              [115.770367, 40.46493],
              [115.772856, 40.462301],
              [115.779527, 40.464075],
              [115.7734, 40.457397],
              [115.77547, 40.45204],
              [115.77248, 40.452831],
              [115.773546, 40.44812],
              [115.77043, 40.444166],
              [115.786679, 40.437066],
              [115.78992, 40.432483],
              [115.796884, 40.432531],
              [115.796445, 40.426834],
              [115.836555, 40.38568],
              [115.837454, 40.381255],
              [115.840633, 40.381094],
              [115.846865, 40.375085],
              [115.855962, 40.37712],
              [115.861859, 40.373422],
              [115.861922, 40.364811],
              [115.85981, 40.36213],
              [115.86441, 40.36192],
              [115.864452, 40.359335],
              [115.872754, 40.359593],
              [115.876226, 40.362146],
              [115.878066, 40.359367],
              [115.883169, 40.359545],
              [115.88526, 40.357024],
              [115.890927, 40.357897],
              [115.892997, 40.355554],
              [115.909539, 40.357622],
              [115.918301, 40.35389],
              [115.920581, 40.346473],
              [115.924094, 40.341059],
              [115.921417, 40.338668],
              [115.922881, 40.332898],
              [115.92721, 40.329601],
              [115.922672, 40.325899],
              [115.926792, 40.319611],
              [115.929239, 40.32105],
              [115.935721, 40.316717],
              [115.937185, 40.313047],
              [115.943814, 40.310945],
              [115.939757, 40.304397],
              [115.945885, 40.296199],
              [115.946115, 40.289034],
              [115.950088, 40.289228],
              [115.951698, 40.282015],
              [115.955212, 40.276984],
              [115.960231, 40.274914],
              [115.961611, 40.269219],
              [115.967006, 40.265612],
              [115.968888, 40.264269],
              [115.965605, 40.259415],
              [115.960001, 40.256648],
              [115.950276, 40.256163],
              [115.942706, 40.253557],
              [115.935826, 40.25558],
              [115.916984, 40.247068],
              [115.916628, 40.242391],
              [115.911965, 40.234477],
              [115.906695, 40.23412],
              [115.898079, 40.236419],
              [115.898476, 40.234509],
              [115.891994, 40.228147],
              [115.891366, 40.225379],
              [115.883106, 40.216119],
              [115.885072, 40.212039],
              [115.883169, 40.209594],
              [115.886326, 40.206663],
              [115.877313, 40.200849],
              [115.87633, 40.193918],
              [115.873695, 40.192687],
              [115.870308, 40.186079],
              [115.863072, 40.186095],
              [115.855502, 40.188865],
              [115.848099, 40.183843],
              [115.854205, 40.179939],
              [115.846865, 40.169458],
              [115.844418, 40.168016],
              [115.84676, 40.163171],
              [115.853557, 40.154162],
              [115.853348, 40.149332],
              [115.846384, 40.147096],
              [115.834234, 40.15024],
              [115.83576, 40.145426],
              [115.829047, 40.149981],
              [115.822272, 40.152606],
              [115.806567, 40.153254],
              [115.802091, 40.156754],
              [115.789837, 40.168939],
              [115.78693, 40.170414],
              [115.787014, 40.178708],
              [115.773023, 40.176197],
              [115.768213, 40.165553],
              [115.762212, 40.16262],
              [115.754328, 40.163252],
              [115.749539, 40.152995],
              [115.75485, 40.145459],
              [115.749246, 40.137711],
              [115.741111, 40.132216],
              [115.734126, 40.129379],
              [115.724841, 40.128812],
              [115.715891, 40.133383],
              [115.708697, 40.134291],
              [115.699746, 40.132394],
              [115.704765, 40.129655],
              [115.711039, 40.128941],
              [115.712064, 40.126899],
              [115.702172, 40.128196],
              [115.697216, 40.12672],
              [115.693096, 40.131924],
              [115.68699, 40.13053],
              [115.681197, 40.13267],
              [115.678667, 40.130935],
              [115.657734, 40.128098],
              [115.654806, 40.131276],
              [115.64458, 40.126639],
              [115.641882, 40.120819],
              [115.643722, 40.117511],
              [115.635943, 40.115793],
              [115.631468, 40.117852],
              [115.625048, 40.116295],
              [115.621388, 40.118711],
              [115.616223, 40.117138],
              [115.606979, 40.120057],
              [115.599116, 40.120008],
              [115.59485, 40.116279],
              [115.594432, 40.108982],
              [115.592403, 40.110182],
              [115.590709, 40.096397],
              [115.584038, 40.094889],
              [115.578538, 40.096365],
              [115.576196, 40.100825],
              [115.567769, 40.096543],
              [115.563419, 40.097922],
              [115.553736, 40.091661],
              [115.555472, 40.082626],
              [115.552168, 40.079252],
              [115.544263, 40.07591],
              [115.537885, 40.077775],
              [115.527324, 40.076072],
              [115.514944, 40.066937],
              [115.509695, 40.065477],
              [115.510427, 40.062913],
              [115.500954, 40.052478],
              [115.488323, 40.046132],
              [115.488992, 40.043746],
              [115.478557, 40.036165],
              [115.468414, 40.031896],
              [115.460656, 40.032172],
              [115.454528, 40.029704],
              [115.452082, 40.02079],
              [115.442169, 40.010885],
              [115.442817, 40.007345],
              [115.449196, 40.001985],
              [115.450346, 39.993247],
              [115.443905, 39.994644],
              [115.436815, 39.991427],
              [115.428513, 39.984328],
              [115.427635, 39.979471],
              [115.423411, 39.969819],
              [115.426924, 39.965302],
              [115.423745, 39.955697],
              [115.42615, 39.95035],
              [115.43577, 39.950919],
              [115.438468, 39.95256],
              [115.444595, 39.951358],
              [115.447042, 39.948806],
              [115.452312, 39.948188],
              [115.456787, 39.944271],
              [115.464462, 39.940142],
              [115.472387, 39.93876],
              [115.48069, 39.93585],
              [115.487277, 39.923835],
              [115.494994, 39.917948],
              [115.50386, 39.915818],
              [115.52013, 39.902547],
              [115.523016, 39.898919],
              [115.509026, 39.884164],
              [115.51003, 39.88148],
              [115.516659, 39.880406],
              [115.526299, 39.875655],
              [115.529185, 39.875948],
              [115.527345, 39.869862],
              [115.521929, 39.868186],
              [115.522368, 39.858779],
              [115.515948, 39.847678],
              [115.510992, 39.84509],
              [115.514505, 39.83835],
              [115.526509, 39.835241],
              [115.530482, 39.829916],
              [115.534957, 39.830714],
              [115.546396, 39.825992],
              [115.548027, 39.822703],
              [115.563461, 39.816417],
              [115.569274, 39.813274],
              [115.566367, 39.809788],
              [115.566577, 39.804609],
              [115.56229, 39.803713],
              [115.554866, 39.795601],
              [115.539432, 39.794754],
              [115.536275, 39.792131],
              [115.513815, 39.788693],
              [115.508712, 39.784082],
              [115.497336, 39.791088],
              [115.49238, 39.796057],
              [115.483241, 39.798679],
              [115.475859, 39.791821],
              [115.45777, 39.782143],
              [115.452751, 39.781964],
              [115.443382, 39.785646],
              [115.434076, 39.782274],
              [115.431169, 39.775756],
              [115.425209, 39.77336],
              [115.427029, 39.769775],
              [115.430918, 39.772073],
              [115.435414, 39.769938],
              [115.434411, 39.763859],
              [115.439158, 39.752678],
              [115.457728, 39.744918],
              [115.46672, 39.740451],
              [115.470568, 39.742391],
              [115.482321, 39.742473],
              [115.492108, 39.73887],
              [115.488866, 39.733163],
              [115.491229, 39.714719],
              [115.493404, 39.707494],
              [115.490247, 39.701409],
              [115.492631, 39.701719],
              [115.49926, 39.696189],
              [115.499783, 39.691278],
              [115.496395, 39.685665],
              [115.494408, 39.686481],
              [115.488783, 39.681619],
              [115.489724, 39.678012],
              [115.486733, 39.673362],
              [115.491334, 39.668694],
              [115.482593, 39.66303],
              [115.477971, 39.654216],
              [115.478515, 39.650331],
              [115.494659, 39.649237],
              [115.496771, 39.652551],
              [115.506705, 39.652127],
              [115.511493, 39.644388],
              [115.515864, 39.641237],
              [115.522452, 39.639964],
              [115.520423, 39.633416],
              [115.521699, 39.622311],
              [115.523414, 39.620384],
              [115.514358, 39.613508],
              [115.512121, 39.605129],
              [115.515948, 39.591193],
              [115.518834, 39.593072],
              [115.518311, 39.597156],
              [115.524229, 39.598937],
              [115.530586, 39.602874],
              [115.533891, 39.608608],
              [115.533263, 39.611434],
              [115.539119, 39.616285],
              [115.545978, 39.618751],
              [115.551875, 39.614064],
              [115.55451, 39.609408],
              [115.564569, 39.605619],
              [115.567267, 39.599623],
              [115.573875, 39.596552],
              [115.571867, 39.591569],
              [115.586109, 39.589412],
              [115.592445, 39.59665],
              [115.598719, 39.597761],
              [115.599785, 39.600865],
              [115.605285, 39.600032],
              [115.6125, 39.601126],
              [115.618439, 39.604067],
              [115.625947, 39.599394],
              [115.632555, 39.597695],
              [115.634688, 39.603871],
              [115.641589, 39.603332],
              [115.643576, 39.598937],
              [115.650268, 39.600996],
              [115.657273, 39.600081],
              [115.665304, 39.605325],
              [115.667583, 39.609637],
              [115.667479, 39.615256],
              [115.673271, 39.608526],
              [115.685317, 39.603675],
              [115.68929, 39.599035],
              [115.689269, 39.592941],
              [115.694226, 39.587778],
              [115.693431, 39.580327],
              [115.697906, 39.579248],
              [115.698596, 39.570586],
              [115.694393, 39.56941],
              [115.692072, 39.565781],
              [115.698722, 39.563248],
              [115.710161, 39.563019],
              [115.717104, 39.560403],
              [115.72022, 39.554747],
              [115.7216, 39.543499],
              [115.726953, 39.543908],
              [115.726765, 39.548143],
              [115.739124, 39.545363],
              [115.73879, 39.539314],
              [115.741487, 39.536289],
              [115.743934, 39.526771],
              [115.752508, 39.515453],
              [115.759451, 39.513916],
              [115.765328, 39.514848],
              [115.768736, 39.508878],
              [115.770828, 39.510971],
              [115.767419, 39.515862],
              [115.776537, 39.512722],
              [115.777917, 39.513834],
              [115.785006, 39.51035],
              [115.792681, 39.510742],
              [115.821456, 39.509499],
              [115.828692, 39.507045],
              [115.829487, 39.512885],
              [115.822146, 39.514145],
              [115.819762, 39.518528],
              [115.824447, 39.518774],
              [115.824112, 39.522405],
              [115.819804, 39.524923],
              [115.819219, 39.530762],
              [115.822753, 39.530533],
              [115.824321, 39.534212],
              [115.828399, 39.535455],
              [115.828692, 39.541309],
              [115.84216, 39.54157],
              [115.846028, 39.543287],
              [115.847555, 39.550284],
              [115.851361, 39.550448],
              [115.855481, 39.554993],
              [115.862026, 39.548551],
              [115.866041, 39.549843],
              [115.866355, 39.546361],
              [115.872315, 39.546099],
              [115.873298, 39.548829],
              [115.88296, 39.54811],
              [115.883587, 39.551102],
              [115.887686, 39.55066],
              [115.888752, 39.555614],
              [115.89306, 39.556219],
              [115.893416, 39.561875],
              [115.890028, 39.567873],
              [115.896009, 39.569916],
              [115.907866, 39.566876],
              [115.91276, 39.572842],
              [115.911777, 39.574182],
              [115.915604, 39.582958],
              [115.908744, 39.58402],
              [115.909665, 39.588284],
              [115.9068, 39.590016],
              [115.910187, 39.600832],
              [115.912488, 39.599149],
              [115.923759, 39.597287],
              [115.924178, 39.59384],
              [115.930221, 39.593382],
              [115.929991, 39.589935],
              [115.934174, 39.588072],
              [115.934969, 39.581814],
              [115.938105, 39.581699],
              [115.937938, 39.577467],
              [115.943187, 39.577385],
              [115.943083, 39.574672],
              [115.949147, 39.573299],
              [115.950423, 39.56637],
              [115.954982, 39.566092],
              [115.957554, 39.560927],
              [115.963409, 39.565503],
              [115.967592, 39.564604],
              [115.968909, 39.570995],
              [115.974576, 39.570832],
              [115.978153, 39.572842],
              [115.977086, 39.590931],
              [115.978445, 39.595686],
              [115.990993, 39.593791],
              [115.990721, 39.586471],
              [115.996953, 39.583203],
              [115.995196, 39.577075],
              [116.007618, 39.577205],
              [116.010588, 39.583023],
              [116.013703, 39.583039],
              [116.014038, 39.588072],
              [116.020667, 39.585981],
              [116.02623, 39.587402],
              [116.024766, 39.575604],
              [116.032859, 39.574607],
              [116.032964, 39.572302],
              [116.039237, 39.571943],
              [116.098817, 39.575146],
              [116.102016, 39.576143],
              [116.101368, 39.580049],
              [116.105801, 39.576568],
              [116.106282, 39.570979],
              [116.11379, 39.570668],
              [116.116634, 39.574002],
              [116.121465, 39.574917],
              [116.121528, 39.570554],
              [116.130311, 39.569459],
              [116.130373, 39.567743],
              [116.138425, 39.568887],
              [116.13878, 39.571044],
              [116.149613, 39.573087],
              [116.151788, 39.583415],
              [116.165527, 39.583562],
              [116.176924, 39.585899],
              [116.177071, 39.590016],
              [116.184432, 39.590915],
              [116.190768, 39.589396],
              [116.19058, 39.587386],
              [116.196854, 39.588987],
              [116.196394, 39.586095],
              [116.201726, 39.586373],
              [116.206243, 39.583219],
              [116.208105, 39.577728],
              [116.221175, 39.578921],
              [116.225817, 39.568151],
              [116.229373, 39.565471],
              [116.236462, 39.568396],
              [116.234684, 39.563934],
              [116.240644, 39.564098],
              [116.243007, 39.55836],
              [116.246186, 39.557167],
              [116.242505, 39.552966],
              [116.246521, 39.539788],
              [116.245601, 39.53014],
              [116.248256, 39.530271],
              [116.246479, 39.525299],
              [116.24353, 39.524236],
              [116.246709, 39.520098],
              [116.245831, 39.514897],
              [116.253861, 39.510055],
              [116.25706, 39.505491],
              [116.257939, 39.500518],
              [116.269315, 39.495495],
              [116.275631, 39.495201],
              [116.279269, 39.491306],
              [116.283201, 39.493941],
              [116.305284, 39.489179],
              [116.306957, 39.485023],
              [116.312708, 39.480556],
              [116.314779, 39.476104],
              [116.319944, 39.473436],
              [116.320048, 39.468543],
              [116.325088, 39.466153],
              [116.325611, 39.462961],
              [116.334499, 39.457019],
              [116.350162, 39.45291],
              [116.351124, 39.455529],
              [116.362187, 39.454874],
              [116.367791, 39.451633],
              [116.373563, 39.452058],
              [116.388557, 39.450732],
              [116.391903, 39.452893],
              [116.399787, 39.450044],
              [116.408947, 39.450257],
              [116.425635, 39.446885],
              [116.434397, 39.442758],
              [116.437241, 39.445951],
              [116.450353, 39.448522],
              [116.450667, 39.452648],
              [116.454682, 39.453302],
              [116.453992, 39.45751],
              [116.448638, 39.465122],
              [116.448764, 39.476284],
              [116.444059, 39.47887],
              [116.444142, 39.482192],
              [116.436258, 39.482912],
              [116.433644, 39.478183],
              [116.428333, 39.476219],
              [116.425342, 39.481259],
              [116.429964, 39.481325],
              [116.427329, 39.487788],
              [116.423544, 39.485154],
              [116.412376, 39.482077],
              [116.411582, 39.485105],
              [116.415827, 39.48823],
              [116.418504, 39.496575],
              [116.422895, 39.496608],
              [116.418734, 39.506391],
              [116.40857, 39.508011],
              [116.407253, 39.512116],
              [116.40282, 39.51439],
              [116.402652, 39.526886],
              [116.405538, 39.528194],
              [116.411247, 39.524678],
              [116.421473, 39.525103],
              [116.424046, 39.522732],
              [116.423125, 39.516337],
              [116.424631, 39.509728],
              [116.431699, 39.51053],
              [116.433017, 39.507438],
              [116.443829, 39.509875],
              [116.442741, 39.516189],
              [116.440378, 39.516271],
              [116.439876, 39.523353],
              [116.437513, 39.52651],
              [116.440985, 39.527311],
              [116.45372, 39.526477],
              [116.453846, 39.528652],
              [116.464553, 39.527638],
              [116.464595, 39.531628],
              [116.468819, 39.534359],
              [116.478188, 39.535487],
              [116.47802, 39.543205],
              [116.475134, 39.545756],
              [116.470952, 39.5546],
              [116.473378, 39.553096],
              [116.48948, 39.553472],
              [116.489773, 39.550268],
              [116.508448, 39.551053],
              [116.50805, 39.560256],
              [116.510581, 39.560502],
              [116.511564, 39.565503],
              [116.519385, 39.566484],
              [116.520389, 39.57191],
              [116.527248, 39.57294],
              [116.52614, 39.577271],
              [116.520389, 39.577156],
              [116.519699, 39.581863],
              [116.52317, 39.586242],
              [116.521267, 39.590229],
              [116.525052, 39.593807],
              [116.524446, 39.596535],
              [116.530615, 39.598774],
              [116.530866, 39.596715],
              [116.541803, 39.59348],
              [116.540883, 39.60142],
              [116.544124, 39.603609],
              [116.544187, 39.596519],
              [116.549436, 39.596143],
              [116.557132, 39.601502],
              [116.562276, 39.601714],
              [116.566605, 39.604361],
              [116.566103, 39.61114],
              [116.569637, 39.61176],
              [116.565978, 39.616138],
              [116.565936, 39.61978],
              [116.579382, 39.619666],
              [116.579194, 39.623487],
              [116.591993, 39.621299],
              [116.593561, 39.618588],
              [116.600128, 39.619649],
              [116.602177, 39.624533],
              [116.607886, 39.624696],
              [116.607781, 39.619698],
              [116.611755, 39.618882],
              [116.613177, 39.613802],
              [116.616648, 39.614096],
              [116.616857, 39.607301],
              [116.620182, 39.606893],
              [116.620517, 39.601665],
              [116.628338, 39.599558],
              [116.635407, 39.599934],
              [116.635595, 39.604818],
              [116.645549, 39.60209],
              [116.646532, 39.599117],
              [116.657678, 39.60075],
              [116.6568, 39.602776],
              [116.662384, 39.60521],
              [116.670037, 39.604916],
              [116.669975, 39.603381],
              [116.6889, 39.598496],
              [116.694087, 39.601355],
              [116.696408, 39.595392],
              [116.699127, 39.595457],
              [116.700695, 39.590964],
              [116.705108, 39.587974],
              [116.727065, 39.593055],
              [116.724953, 39.598006],
              [116.718324, 39.601077],
              [116.718282, 39.603021],
              [116.702577, 39.610421],
              [116.700737, 39.62107],
              [116.705338, 39.621462],
              [116.70929, 39.618114],
              [116.716149, 39.62156],
              [116.721858, 39.621756],
              [116.725518, 39.624075],
              [116.730202, 39.622932],
              [116.730516, 39.619143],
              [116.737877, 39.61537],
              [116.744004, 39.616824],
              [116.748689, 39.619943],
              [116.762616, 39.613819],
              [116.774202, 39.605439],
              [116.774892, 39.599166],
              [116.778196, 39.593382],
              [116.785055, 39.593497],
              [116.785474, 39.596209],
              [116.790702, 39.596045],
              [116.789384, 39.602596],
              [116.792835, 39.602155],
              [116.790012, 39.610535],
              [116.802078, 39.6123],
              [116.809711, 39.614521],
              [116.81954, 39.618996],
              [116.823994, 39.617183],
              [116.82504, 39.613884],
              [116.835391, 39.617004],
              [116.834116, 39.621495],
              [116.838445, 39.62223],
              [116.826357, 39.633122],
              [116.82893, 39.635163],
              [116.826901, 39.638217],
              [116.834555, 39.641841],
              [116.833572, 39.644127],
              [116.840766, 39.644241],
              [116.839135, 39.647523],
              [116.85141, 39.652845],
              [116.849946, 39.667552],
              [116.860486, 39.667258],
              [116.863979, 39.670391],
              [116.87318, 39.671387],
              [116.883239, 39.675352],
              [116.891144, 39.67408],
              [116.906661, 39.677425],
              [116.905197, 39.681651],
              [116.909024, 39.682859],
              [116.902896, 39.690576],
              [116.88991, 39.687656],
              [116.887819, 39.690952],
              [116.89336, 39.693187],
              [116.893841, 39.695879],
              [116.886376, 39.707004],
              [116.887108, 39.714311],
              [116.8828, 39.71847],
              [116.887589, 39.725515],
              [116.89976, 39.726168],
              [116.90229, 39.729413],
              [116.911533, 39.731516],
              [116.916678, 39.731353],
              [116.916364, 39.73587],
              [116.912934, 39.73569],
              [116.910718, 39.740989],
              [116.914461, 39.741755],
              [116.913185, 39.745962],
              [116.907163, 39.75597],
              [116.901558, 39.755204],
              [116.901809, 39.763615],
              [116.908292, 39.766711],
              [116.910613, 39.762278],
              [116.920902, 39.769107],
              [116.91649, 39.775935],
              [116.921718, 39.780628],
              [116.933784, 39.781801],
              [116.939284, 39.781361],
              [116.945788, 39.777369],
              [116.94974, 39.778542],
              [116.948004, 39.785369],
              [116.953797, 39.78607],
              [116.950828, 39.791528],
              [116.938301, 39.793124],
              [116.934809, 39.801139],
              [116.942881, 39.801677],
              [116.92979, 39.811368],
              [116.92818, 39.814153],
              [116.92887, 39.820912],
              [116.9259, 39.835403],
              [116.917431, 39.846913],
              [116.910383, 39.850608],
              [116.902813, 39.848248],
              [116.902896, 39.841346],
              [116.907581, 39.834117],
              [116.903357, 39.830682],
              [116.897501, 39.832587],
              [116.885665, 39.844585],
              [116.878304, 39.84522],
              [116.878638, 39.842257],
              [116.871507, 39.842062],
              [116.866049, 39.843902],
              [116.865505, 39.846913],
              [116.85829, 39.84846],
              [116.85254, 39.859056],
              [116.847249, 39.858616],
              [116.839407, 39.865777],
              [116.836897, 39.864736],
              [116.827277, 39.877071],
              [116.823681, 39.879137],
              [116.817009, 39.878649],
              [116.81312, 39.881301],
              [116.812304, 39.889712],
              [116.80831, 39.889631],
              [116.808247, 39.884913],
              [116.804253, 39.88488],
              [116.804148, 39.877933],
              [116.794738, 39.881252],
              [116.787084, 39.886833],
              [116.7847, 39.89142],
              [116.78424, 39.902221],
              [116.78217, 39.910419],
              [116.782358, 39.928273],
              [116.78332, 39.936045],
              [116.782567, 39.947554],
              [116.78058, 39.949716],
              [116.762826, 39.956006],
              [116.757326, 39.961483],
              [116.759605, 39.969933],
              [116.766443, 39.976351],
              [116.766757, 39.982281],
              [116.775373, 39.992759],
              [116.775749, 40.002943],
              [116.770459, 40.011632],
              [116.771755, 40.014474],
              [116.777485, 40.027204],
              [116.77782, 40.032448],
              [116.781542, 40.034818],
              [116.790221, 40.034477],
              [116.789531, 40.032318],
              [116.800259, 40.028844],
              [116.803375, 40.032155],
              [116.815295, 40.030905],
              [116.82, 40.028357],
              [116.820816, 40.038779],
              [116.823158, 40.039834],
              [116.822739, 40.046473],
              [116.831732, 40.048485],
              [116.831502, 40.051196],
              [116.849486, 40.051926],
              [116.850197, 40.054977],
              [116.857809, 40.051894],
              [116.867826, 40.041863],
              [116.873619, 40.041522],
              [116.88075, 40.046164],
              [116.890265, 40.04597],
              [116.90137, 40.047723],
              [116.906431, 40.051423],
              [116.914252, 40.052592],
              [116.917974, 40.044704],
              [116.924164, 40.047463],
              [116.928096, 40.054929],
              [116.931693, 40.052024],
              [116.938824, 40.050887],
              [116.937632, 40.046911],
              [116.945014, 40.048631],
              [116.945265, 40.041425],
              [116.961932, 40.051358],
              [116.964902, 40.047836],
              [116.969293, 40.048583],
              [116.972095, 40.036977],
              [116.985626, 40.038828],
              [116.991837, 40.036896],
              [117.00016, 40.032253],
              [117.000683, 40.029915],
              [117.011369, 40.031246],
              [117.018061, 40.030467],
              [117.020884, 40.032448],
              [117.024836, 40.03011],
              [117.023916, 40.033746],
              [117.028517, 40.033957],
              [117.027032, 40.038828],
              [117.033159, 40.04235],
              [117.038639, 40.049378],
              [117.051437, 40.051163],
              [117.053382, 40.052884],
              [117.052022, 40.059375],
              [117.061182, 40.060105],
              [117.064382, 40.062783],
              [117.070634, 40.064179],
              [117.069652, 40.06757],
              [117.081049, 40.068819],
              [117.080986, 40.065087],
              [117.085064, 40.068592],
              [117.085608, 40.075131],
              [117.103927, 40.075585],
              [117.107775, 40.071805],
              [117.119507, 40.072421],
              [117.128896, 40.06546],
              [117.139227, 40.064049],
              [117.15625, 40.069338],
              [117.160139, 40.075553],
              [117.158466, 40.077435],
              [117.172227, 40.074157],
              [117.175593, 40.071642],
              [117.183603, 40.072081],
              [117.186426, 40.076202],
              [117.181533, 40.080095],
              [117.18538, 40.083875],
              [117.189207, 40.082853],
              [117.191842, 40.072973],
              [117.198576, 40.070101],
              [117.197572, 40.067748],
              [117.205247, 40.07028],
              [117.208175, 40.076834],
              [117.203616, 40.076704],
              [117.204285, 40.079657],
              [117.213989, 40.086243],
              [117.21104, 40.090785],
              [117.211249, 40.096608],
              [117.224487, 40.094662],
              [117.224403, 40.098619],
              [117.228606, 40.100257],
              [117.229025, 40.103533],
              [117.236009, 40.108382],
              [117.238226, 40.111755],
              [117.245357, 40.113215],
              [117.249268, 40.116474],
              [117.255541, 40.113279],
              [117.260393, 40.114155],
              [117.266834, 40.112177],
              [117.269908, 40.107198],
              [117.274362, 40.105804],
              [117.276663, 40.109307],
              [117.275659, 40.113636],
              [117.285425, 40.121322],
              [117.297094, 40.118857],
              [117.297073, 40.121273],
              [117.302991, 40.125926],
              [117.307613, 40.136982],
              [117.313761, 40.139964],
              [117.318571, 40.138522],
              [117.323276, 40.14071],
              [117.33093, 40.13575],
              [117.330617, 40.133691],
              [117.349082, 40.136528],
              [117.351404, 40.139932],
              [117.35636, 40.140904],
              [117.356883, 40.145037],
              [117.350525, 40.144827],
              [117.355272, 40.148587],
              [117.351717, 40.150564],
              [117.360626, 40.156965],
              [117.357343, 40.164273],
              [117.351111, 40.171661],
              [117.353746, 40.17367],
              [117.359413, 40.173346],
              [117.364014, 40.176683],
              [117.368844, 40.17299],
              [117.372023, 40.176538],
              [117.377021, 40.176327],
              [117.381225, 40.172455],
              [117.380597, 40.17691],
              [117.393186, 40.174901],
              [117.391618, 40.177607],
              [117.401217, 40.183617],
              [117.404751, 40.183244],
              [117.4077, 40.187504],
              [117.397704, 40.192914],
              [117.388356, 40.188249],
              [117.38409, 40.187828],
              [117.384382, 40.195278],
              [117.381455, 40.194906],
              [117.379552, 40.201319],
              [117.393145, 40.203376],
              [117.385679, 40.207894],
              [117.378443, 40.21029],
              [117.377586, 40.218612],
              [117.39373, 40.221656],
              [117.390029, 40.227969],
              [117.386829, 40.227111],
              [117.373989, 40.232777],
              [117.36027, 40.23255],
              [117.355691, 40.229556],
              [117.351613, 40.229459],
              [117.348246, 40.234574],
              [117.345464, 40.234946],
              [117.343457, 40.242909],
              [117.339881, 40.246194],
              [117.342202, 40.256502],
              [117.337622, 40.263266],
              [117.337999, 40.265903],
              [117.334005, 40.285654],
              [117.331244, 40.289665],
              [117.32336, 40.284441],
              [117.316794, 40.285104],
              [117.316835, 40.281999],
              [117.304309, 40.278181],
              [117.29632, 40.2781],
              [117.292368, 40.286236],
              [117.294647, 40.290894],
              [117.293309, 40.296748],
              [117.285739, 40.302214],
              [117.274342, 40.308552],
              [117.274697, 40.314405],
              [117.271853, 40.319853],
              [117.271288, 40.325285],
              [117.274969, 40.331944],
              [117.267127, 40.335694],
              [117.259828, 40.336195],
              [117.261125, 40.338781],
              [117.257089, 40.341463],
              [117.25437, 40.351191],
              [117.254182, 40.357105],
              [117.250188, 40.358381],
              [117.247762, 40.364101],
              [117.242283, 40.369981],
              [117.237055, 40.370627],
              [117.22618, 40.369044],
              [117.223901, 40.375538],
            ],
          ],
        ],
      },
    },
  ],
};

export const cycling_datas = [
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240916户外骑行_01.gpx",
    lon: 0.0062,
    lat: 0.00129,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240908户外骑行.gpx",
    lon: 0.0062,
    lat: 0.00129,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240829户外骑行_01.gpx",
    lon: 0.0061,
    lat: 0.00138,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240829户外骑行.gpx",
    lon: 0.0061,
    lat: 0.00138,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240828户外骑行_01.gpx",
    lon: 0.0061,
    lat: 0.0014,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240828户外骑行.gpx",
    lon: 0.0061,
    lat: 0.00129,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240817户外骑行.gpx",
    lon: 0.0061,
    lat: 0.00129,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240815户外骑行.gpx",
    lon: 0.0061,
    lat: 0.00129,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240803户外骑行.gpx",
    lon: 0.0061,
    lat: 0.00129,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240722户外骑行.gpx",
    lon: 0.0061,
    lat: 0.00129,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240721户外骑行.gpx",
    lon: 0.0061,
    lat: 0.00129,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240721户外骑行_01.gpx",
    lon: 0.0061,
    lat: 0.00129,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240721户外骑行_02.gpx",
    lon: 0.0061,
    lat: 0.00129,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240714户外骑行.gpx",
    lon: 0.0061,
    lat: 0.00129,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240707户外骑行.gpx",
    lon: 0.0061,
    lat: 0.00136,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240704户外骑行.gpx",
    lon: 0.0061,
    lat: 0.00129,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240615户外骑行.gpx",
    lon: 0.0062,
    lat: 0.00133,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240614户外骑行.gpx",
    lon: 0.0063,
    lat: 0.00136,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240608户外骑行.gpx",
    lon: 0.00585,
    lat: 0.00106,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240527户外骑行.gpx",
    lon: 0.006225,
    lat: 0.00136,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240526户外骑行.gpx",
    lon: 0.0062,
    lat: 0.00136,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240517户外骑行.gpx",
    lon: 0.0062,
    lat: 0.00136,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240516户外骑行.gpx",
    lon: 0.0062,
    lat: 0.00136,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240516户外骑行_01.gpx",
    lon: 0.0062,
    lat: 0.00136,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240515户外骑行.gpx",
    lon: 0.0062,
    lat: 0.00136,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240515户外骑行_01.gpx",
    lon: 0.0062,
    lat: 0.00136,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240420户外骑行.gpx",
    lon: 0.0062,
    lat: 0.0015,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240419户外骑行.gpx",
    lon: 0.00615,
    lat: 0.0012,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240417户外骑行.gpx",
    lon: 0.00612,
    lat: 0.0013,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240413户外骑行.gpx",
    lon: 0.00612,
    lat: 0.0013,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240412户外骑行.gpx",
    lon: 0.00625,
    lat: 0.0014,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20240330户外骑行.gpx",
    lon: 0.00605,
    lat: 0.00128,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20230916户外骑行.gpx",
    lon: 0.006,
    lat: 0.0011,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20230910户外骑行.gpx",
    lon: 0.0061,
    lat: 0.00131,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20230903户外骑行.gpx",
    lon: 0.00616,
    lat: 0.00135,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20230827户外骑行.gpx",
    lon: 0.00617,
    lat: 0.00125,
  },
  {
    url: "https://vastwain.oss-cn-beijing.aliyuncs.com/cycling/20230819户外骑行.gpx",
    lon: 0.00612,
    lat: 0.0012,
  },
];
