<template>
  <BaseLayout :hidden="isHome" :mode="!isCycling">
    <HomePage v-if="isHome" />
    <CyclingPage v-else-if="isCycling" :siteUri="siteUri" />
    <NotePage v-else :siteUri="siteUri" :notes="notes" />
  </BaseLayout>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import CyclingPage from "./pages/CyclingPage.vue";
import HomePage from "./pages/HomePage.vue";
import NotePage from "./pages/NotePage.vue";
import BaseLayout from "./components/BaseLayout.vue";

const appContext = getCurrentInstance().appContext;
const siteUri = ref(window.location.pathname);
const isHome = ref(!siteUri.value.startsWith("/note") && !siteUri.value.startsWith("/cycling"));
const isCycling = ref(siteUri.value.startsWith("/cycling"));
const notes = ref([]);

async function fetchNotes() {
  const url = window.location.origin + "/notes.json";
  try {
    const response = await fetch(url);
    if (!response.ok) {
      return [];
    }
    const fetchedNotes = await response.json();
    notes.value = fetchedNotes;

    fetchedNotes.forEach((item) => {
      if (item.url === siteUri.value) {
        appContext.config.globalProperties.$site.note = item;
      }
    });
  } catch (error) {
    console.error("There was a problem with your fetch operation:", error);
  }
}

document.title = appContext.config.globalProperties.$site.name;

onMounted(() => {
  // document.title = appContext.config.globalProperties.$site.name;

  siteUri.value = window.location.pathname;
  isHome.value = !siteUri.value.startsWith("/note") && !siteUri.value.startsWith("/cycling");
  isCycling.value = siteUri.value.startsWith("/cycling");

  if (!isHome.value && !isCycling.value) {
    fetchNotes();
  }
});
</script>

<style>
#app {
  quotes: "“" "”";
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

:root {
  --black: #1d1d1f;
  --bg-primary: #f5f5f7;
  --gray-primary: #f2f2f2;
  --gray-text: #515154;
  --primary: #8129d9;
  --blue: #0071e3;
  --footer-background: #f5f5f7;
  --footer-border-color: #d2d2d7;
  --footer-text-color: #6e6e73;
  --footer-link-color: #424245;
  --footer-pipe-color: #86868b;
  --footer-directory-title-color: #1d1d1f;
  --footer-directory-title-color-hover: #000;
}

a {
  color: inherit;
  text-decoration: inherit;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

a:link,
a:visited,
.link:link,
.link:visited {
  text-decoration: none;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  line-height: inherit;
}

*,
:before,
:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

.li1:nth-of-type(n + 2) {
  background: red;
  flex-basis: 50%;
}

.li1:nth-of-type(n + 6) {
  background: yellow;
  flex-basis: 33%;
}
</style>

<style>
.theme-dark {
  background-color: #000;
  color: #f5f5f7;
  --footer-background: #121214;
  --footer-border-color: #424245;
  --footer-text-color: #86868b;
  --footer-link-color: #d2d2d7;
  --footer-pipe-color: #6e6e73;
  --footer-directory-title-color: #f5f5f7;
  --footer-directory-title-color-hover: #fff;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 4px;
}
</style>
