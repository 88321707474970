<template>
  <main>
    <div v-if="siteUri.startsWith('/cycling')">
      <div id="map" style="height: 960px"></div>
    </div>
  </main>
</template>

<script setup>
import { onMounted } from "vue";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { length as turflength } from "@turf/turf";
import togeojson from "@mapbox/togeojson";
import { CN_GEOJSON, cycling_datas } from "../consts.js";

defineProps({
  siteUri: {
    type: String,
    required: true,
    default: "",
  },
});

var selectedLine = null;

//map地图实例 const map=ref(null)
//map地图实例
function initMap() {
  let leafletMap = L.map("map", {
    attributionControl: false, // 关闭Attribution控件
    zoomControl: false,
  })
    .setView([39.767, 116.37], 5)
    .setZoom(10)
    .setMinZoom(8);
  // https://webrd03.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}
  // https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}
  // https://webst01.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}
  // https://webrd04.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}
  // https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}
  // https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
  // https://www.google.com/maps/vt?lyrs=m&gl=cn&x={x}&y={y}&z={z}
  // https://www.google.com/maps/vt?lyrs=s&gl=cn&x={x}&y={y}&z={z}
  // https://www.google.com/maps/vt?lyrs=y&gl=cn&x={x}&y={y}&z={z}
  // https://www.google.com/maps/vt?pb=!1m4!1m3!1i{z}!2i{x}!3i{y}!2m3!1e0!2sm!3i662402849!3m17!2szh-CN!3sCN!5e18!12m4!1e68!2m2!1sset!2sRoadmap!12m3!1e37!2m1!1ssmartmaps!12m4!1e26!2m2!1sstyles!2zcy5lOmwudC5mfHAuYzojZmZmZmZmfHAudzowLjIwfHAubDoyOHxwLnM6MjN8cC52Om9mZixzLmU6bC50LnN8cC5jOiM0OTQ5NDl8cC5sOjEzfHAudjpvZmYscy5lOmwuaXxwLnY6b2ZmLHMudDoxfHMuZTpnLmZ8cC5jOiMwMDAwMDAscy50OjF8cy5lOmcuc3xwLmM6IzE0NGI1M3xwLmw6MTR8cC53OjEuNCxzLnQ6NXxwLmM6IzA4MzA0YixzLnQ6MnxzLmU6Z3xwLmM6IzBjNDE1MnxwLmw6NSxzLnQ6NDl8cy5lOmcuZnxwLmM6IzAwMDAwMCxzLnQ6NDl8cy5lOmcuc3xwLmM6IzBiNDM0ZnxwLmw6MjUscy50OjUwfHMuZTpnLmZ8cC5jOiMwMDAwMDAscy50OjUwfHMuZTpnLnN8cC5jOiMwYjNkNTF8cC5sOjE2LHMudDo1MXxzLmU6Z3xwLmM6IzAwMDAwMCxzLnQ6NHxwLmM6IzE0NjQ3NCxzLnQ6NnxwLmM6IzAyMTAxOQ!4e0!5m1!5f2
  // https://www.google.com/maps/vt?pb=!1m4!1m3!1i{z}!2i{x}!3i{y}!2m3!1e0!2sm!3i662402849!3m17!2szh-CN!3sCN!5e18!12m4!1e68!2m2!1sset!2sRoadmap!12m3!1e37!2m1!1ssmartmaps!12m4!1e26!2m2!1sstyles!2zcy50OjF8cC5zOjB8cC5oOiNmZjAwMDB8cC52Om9mZixzLnQ6NXxzLmU6bHxwLnY6b2ZmLHMudDoyfHAudjpvZmYscy50OjN8cC52Om9mZixzLnQ6NHxwLnY6b2ZmfHAuaDojZmYwMDAwLHMudDo2fHMuZTpsfHAudjpvZmY!4e0!5m1!5f2
  L.tileLayer(
    "https://webrd02.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}",
    {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
      opacity: 0.5,
    }
  ).addTo(leafletMap);

  L.control
    .zoom({
      position: "topleft", // 'topleft'(default), 'topright', 'bottomleft' , 'bottomright'
    })
    .addTo(leafletMap);

  L.control
    .scale({
      position: "bottomleft",
      maxWidth: "100",
      imperial: false,
    })
    .addTo(leafletMap);

  // 点击地图空白区域时恢复默认颜色
  leafletMap.on("click", function () {
    if (selectedLine) {
      selectedLine.setStyle({ color: "blue", weight: 5 });
      selectedLine = null;
    }
  });

  // 监听右键双击事件
  leafletMap.on("contextmenu", function (e) {
    e.originalEvent.preventDefault(); // 阻止默认的右键菜单

    // 使用 setTimeout 来检测双击
    if (!this.lastClick || new Date() - this.lastClick < 300) {
      // 双击事件，回退一个缩放级别
      var currentZoom = leafletMap.getZoom();
      leafletMap.setZoom(currentZoom - 1);
    }
    this.lastClick = new Date();
  });

  // 将GeoJSON添加到地图
  L.geoJSON(CN_GEOJSON, {
    style: function () {
      return {
        fillColor: "#cccccc33", // 可以根据需要自定义填充色
        weight: 2,
        opacity: 1,
        dashArray: "3",
        fillOpacity: 0.7,
      };
    },
  }).addTo(leafletMap);

  // 转换 GeoJSON 中的坐标
  function transformGeoJSON(geojson, lon, lat) {
    function transformCoordinates(coordinates) {
      return coordinates.map((coord) => {
        if (Array.isArray(coord[0])) {
          return transformCoordinates(coord);
        } else {
          return [coord[0] + lon, coord[1] + lat];
        }
      });
    }

    return JSON.parse(JSON.stringify(geojson), (key, value) => {
      if (key === "coordinates") {
        return transformCoordinates(value);
      }
      return value;
    });
  }

  cycling_datas.forEach((tem) => {
    fetch(tem.url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.text();
      })
      .then((gpxString) => {
        // 使用 DOMParser 解析 XML 字符串
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(gpxString, "text/xml");
        // 将 GPX 字符串转换为 GeoJSON 对象
        const geoJSON = togeojson.gpx(xmlDoc);
        // 偏移坐标
        var jsonObject = transformGeoJSON(geoJSON, tem.lon, tem.lat);

        jsonObject.features[0].properties.length = turflength(jsonObject, {
          units: "kilometers",
        }).toFixed(2);

        L.geoJSON(jsonObject, {
          style: function () {
            return {
              weight: 5,
              opacity: 1,
              color: "blue",
            };
          },
          onEachFeature: function (feature, layer) {
            // 点击时弹出面板
            layer.on("click", function () {
              this.bringToFront();
              if (selectedLine) {
                // 恢复上一个选中的线的颜色
                selectedLine.setStyle({ color: "blue", weight: 5 });
              }
              // 设置当前选中线的颜色
              layer.setStyle({ color: "red", weight: 8 });
              selectedLine = layer;
              const popupContent = `    
                    <p>Distance: ${feature.properties.length} KM</p>
              
            `;
              layer.bindPopup(popupContent).openPopup();
            });
          },
        }).addTo(leafletMap);
      })
      .catch((error) => {
        console.error("Error fetching or parsing GPX data:", error);
      });
  });
}
//在Onmounted中调用initMap方法即可建立地图
onMounted(() => {
  initMap();
});
</script>

<style>
#map {
  height: 100vh;
}

#map path:focus {
  outline: 0px;
}

.leaflet-control-container {
  height: 100vh;
  width: 100%;
  position: relative;
}

.leaflet-top {
  top: 50px;
}
</style>
