<template>
  <article class="note-article">
    <div class="note-article-info">
      <h1>{{ $site.note.title }}</h1>
    </div>

    <v-md-preview
      :text="markdown"
      style="display: flex; align-items: center; justify-content: center"
    ></v-md-preview>

    <div class="note-copyright">
      <a
        target="_blank"
        href="https://creativecommons.org/licenses/by-nc-nd/3.0/deed.zh"
      >
        版权声明：版权所有，未经许可，不得转载
      </a>
      <p>发表日期：{{ $site.note.lastModified }}</p>
      <p>
        作者：
        {{ $site.note.author !== undefined ? $site.note.author : author }}
      </p>
    </div>
  </article>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUpdate,
  nextTick,
  getCurrentInstance,
} from "vue";

const appContext = getCurrentInstance().appContext;
const author = ref(appContext.config.globalProperties.$site.name || "");
const markdown = ref("");

onMounted(() => {});

onBeforeUpdate(() => {
  loadMarkdown();
});

function loadMarkdown() {
  if (appContext.config.globalProperties.$site.note.title == undefined) return;
  fetch(appContext.config.globalProperties.$site.note.title + ".md")
    .then((response) => {
      if (!response.ok) {
        window.location.href = window.location.origin;
        return;
      }

      return response.text();
    })
    .then((response) => {
      markdown.value = response;
      nextTick(() => {
        const pElements = document.querySelectorAll("p");
        pElements.forEach((item) => {
          if (item.getAttribute("class") === null) {
            item.setAttribute("class", "md-p");
          }
        });
        var tables = document.getElementsByTagName("table");
        for (var i = 0; i < tables.length; i++) {
          var parentElement = tables[i].parentNode;
          // 检查父元素是否是div且没有'table-wrapper'类
          if (
            parentElement.tagName.toLowerCase() !== "div" ||
            !parentElement.classList.contains("table-wrapper")
          ) {
            var wrapperDiv = document.createElement("div");
            wrapperDiv.classList.add("table-wrapper");
            parentElement.insertBefore(wrapperDiv, tables[i]);
            wrapperDiv.appendChild(tables[i]);
          }
        }
      });
    })
    .catch((error) => {
      console.error("There was a problem with your fetch operation:", error);
      window.location.href = window.location.origin;
    });
}
</script>

<style scope>
.note-article {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  margin: 0 auto;
  max-width: 100ch;
  padding: 6rem 1.5rem 5rem;
}

.note-article-info {
  text-align: center;
}

.note-article-info h1 {
  font-size: 2.2em;
}

@media (max-width: 640px) {
  .note-article-info {
    width: 100%;
    text-align: left;
  }
}

.note-copyright {
  display: flex;
  flex-direction: column;
  padding: 0.875rem 1.75rem 0.875rem 1.75rem;
  margin: 0 auto 3rem;
  border-radius: 0.875rem;
  line-height: 2rem;
  font-size: 0.875em;
  border: 1px solid #d0d7de;
}
img {
  border-radius: 8px;
}
</style>
