<template>
  <div class="waterfall-card">
    <a :href="note.url" class="waterfall-card-link">
      <div class="waterfall-card-media" v-show="screenWidth >= 640">
        <img v-if="note.image" :alt="note.title" :src="note.image" />
        <div v-else style="background-color: #f1f1f166"></div>
      </div>
      <section class="waterfall-card-info">
        <h2 class="waterfall-card-info-tile">{{ note.title }}</h2>

        <div class="waterfall-card-info-excerpt">
          <p>
            {{ note.excerpt }}
          </p>
        </div>
      </section>
    </a>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

defineProps({
  note: {
    type: Object,
    required: true,
    default: () => ({}),
  },
});

const screenWidth = ref(document.body.clientWidth);

onMounted(() => {
  // 如果需要动态监听屏幕宽度变化，可以在这里添加监听器
  // 例如，使用 ResizeObserver 或窗口尺寸变化事件
  // 注意：此处未添加实际的监听逻辑，仅保留原代码的结构
});

// 原代码中的 watch 属于无效配置，因为 screenWidth 是 ref 类型，
// 可直接通过 screenWidth.value 修改，无需额外监听
// 若确实有特殊需求，可使用 watchEffect 或 watch 函数进行观察

// 示例：使用 watchEffect 观察 screenWidth 变化（通常不需要这样做）
// watchEffect(() => {
//   console.log('Screen width changed:', screenWidth.value);
// });
</script>

<style>
.waterfall-card a {
  display: flex;
  flex-direction: row;
}

.waterfall-card-link {
  border-radius: revert;
  overflow: revert;
  z-index: revert;
  position: revert;
  background-color: transparent;
  flex-direction: row;
}

.waterfall-card-media img,
.waterfall-card-media div {
  width: 164px;
  height: 164px;
  min-height: auto;
  flex-basis: auto;
  flex-shrink: 1;
  border-radius: 0.875rem;
}

.waterfall-card-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0.5rem 1.5rem 0.875rem;
}

.waterfall-card-info h2 {
  line-height: 2rem;
  font-weight: 700;
}

.waterfall-card-info-tile:hover {
  color: blue;
}

.waterfall-card-info-excerpt {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.waterfall-card-info-excerpt p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  color: #6b7280;
}
</style>
