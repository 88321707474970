<template>
  <header class="site-header">
    <div class="site-header-title">
      <a href="/">
        <span>{{ $site.name }}</span>
      </a>
    </div>
    <div class="site-menu">
      <div class="site-menu-item" v-for="menu in menus" :key="menu.url">
        <a :href="menu.url">{{ menu.label }}</a>
      </div>
      <div id="color-scheme" :style="{ display: mode ? 'block' : 'none' }" style="cursor: pointer;" @click="handleColorSchemeChange" :value="isDark">🌙</div>
    </div>
  </header>
</template>

<script setup>
import { ref } from 'vue';

defineProps({
  mode: {
    type: Boolean,
    required: true,
    default: false,
  },
});

// 定义响应式数据
const isDark = ref(getComputedStyle(document.documentElement).getPropertyValue('--darkmode').trim());
const menus = [
  {
    url: '/note-waterfall',
    label: 'Blogs',
  },
  {
    url: '/cycling',
    label: 'Cycling',
  },
];

// 定义方法
function handleColorSchemeChange() {
  isDark.value = !isDark.value;
}
</script>

<style scope>
.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  z-index: 6666;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #0000000d;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  backdrop-filter: saturate(180%) blur(20px);
}

.site-header-title a {
  font-weight: 600;
  display: block;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  letter-spacing: 0.03rem;
}

.site-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.site-menu-item {
  margin: 0 0.5rem;
  font-size: 0.875em;
  font-weight: 500;
}

.site-menu-item a {
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
}

@media (max-width: 1024px) {
  #color-scheme {
    display: none;
  }
}
</style>
